import React, { Fragment, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import Prose from '../utilities/Prose'
// Removed temporarily until tests can be added in NGC-3
// import ExpandCollapseString from '../utilities/ExpandCollapseString'
import { returnIconAndLabelFromString } from '../../helper-functions/metadata-functions'
import {
  returnHumanDate,
  returnSortedDateString,
} from '../../helper-functions/date-functions'
import { returnNumberWithOrdinalSuffix } from '../../helper-functions/string-functions'
import ExpandCollapseString from '../utilities/ExpandCollapseString'
import Highlighter from '../Highlighter/Highlighter'
import SearchContext from '../../contexts/SearchContext'
import { PropTypes } from 'prop-types'

const SectionDescription = ({ isPrinting, ...props }) => {
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  const recordContext = useContext(RecordContext)
  const record = recordContext.record

  let description = { content: '', function: '', arrangement: '' }
  description['content'] =
    record.administrativeHistoryNote ||
    record.parent?.administrativeHistoryNote ||
    record.biographicalNote ||
    record.scopeAndContentNote ||
    record.scopeNote

  let dates = false

  if (
    record.inclusiveStartDate ||
    record.inclusiveEndDate ||
    record.coverageStartDate ||
    record.coverageEndDate ||
    record.productionDates ||
    record.releaseDates ||
    record.broadcastDates ||
    record.copyrightDates
  ) {
    dates = true
  } else if (record.ancestors) {
    record.ancestors.map((ancestor) => {
      if (
        ancestor.levelOfDescription === 'series' &&
        (ancestor.inclusiveStartDate || ancestor.inclusiveEndDate)
      ) {
        dates = true
      }
    })
  }

  return (
    <>
      {description.content && (
        <ExpandCollapseString
          more="Show full description..."
          isPrinting={isPrinting}
        >
          <Prose data-testid="nac-description__scope">
            <h2 className="usa-sr-only">Description</h2>
            <Highlighter search={q}>{description.content}</Highlighter>
          </Prose>
        </ExpandCollapseString>
      )}
      {record.shotList && (
        <Prose data-testid="nac-description__shotList" title="Shot List">
          <Highlighter search={q}>{record.shotList}</Highlighter>
        </Prose>
      )}

      {(record.productionSeriesTitle ||
        record.productionSeriesSubtitle ||
        record.productionSeriesNumber) && (
        <Prose
          data-testid="nac-description__production-series"
          title="Production Series"
        >
          {record.productionSeriesTitle && (
            <p>
              Title:{' '}
              <Highlighter search={q}>
                {record.productionSeriesTitle}
              </Highlighter>
            </p>
          )}
          {record.productionSeriesSubtitle && (
            <p>
              Subtitle:{' '}
              <Highlighter search={q}>
                {record.productionSeriesSubtitle}
              </Highlighter>
            </p>
          )}
          {record.productionSeriesNumber && (
            <p>
              Number:{' '}
              <Highlighter search={q}>
                {record.productionSeriesNumber}
              </Highlighter>
            </p>
          )}
        </Prose>
      )}

      {record.arrangement && (
        <Prose data-testid="nac-description__arrangement" title="Arrangement">
          <Highlighter search={q}>{record.arrangement}</Highlighter>
        </Prose>
      )}

      {record.beginCongress && (
        <Prose data-testid="nac-description__congress" title="Congress">
          {record.levelOfDescription === 'item'
            ? 'This item documents the '
            : 'These records document the '}
          <Highlighter search={q}>
            {record.beginCongress !== record.endCongress
              ? `${returnNumberWithOrdinalSuffix(
                  record.beginCongress
                )}–${returnNumberWithOrdinalSuffix(
                  record.endCongress
                )} Congresses.`
              : `${returnNumberWithOrdinalSuffix(
                  record.beginCongress
                )} Congress.`}
          </Highlighter>
        </Prose>
      )}

      {record.custodialHistoryNote && (
        <Prose
          data-testid="nac-description__custodial"
          title="Custodial History"
        >
          <Highlighter search={q}>{record.custodialHistoryNote}</Highlighter>
        </Prose>
      )}

      {dates && (
        <Prose data-testid="nac-description__dates" title="Dates">
          <ul className="add-list-reset list-with-margin">
            {record.productionDates && (
              <li data-testid="nac-description__dates--production">
                {`This ${returnIconAndLabelFromString(
                  record.levelOfDescription
                ).label.toLowerCase()} was produced or created ${
                  record.productionDates.length > 1
                    ? 'on the following dates:'
                    : record.productionDates[0].day
                    ? 'on '
                    : 'in '
                }`}
                {record.productionDates.length > 1 ? (
                  <ul>
                    {record.productionDates.map((date) => (
                      <li key={date.year}>{returnHumanDate(date)}</li>
                    ))}
                  </ul>
                ) : (
                  `${returnSortedDateString(record.productionDates)}.`
                )}
              </li>
            )}
            {record.broadcastDates && (
              <li data-testid="nac-description__dates--broadcast">
                {`This ${returnIconAndLabelFromString(
                  record.levelOfDescription
                ).label.toLowerCase()} was broadcast ${
                  record.broadcastDates.length > 1
                    ? 'on the following dates:'
                    : record.broadcastDates[0].day
                    ? 'on '
                    : 'in '
                }`}
                {record.broadcastDates.length > 1 ? (
                  <ul>
                    {record.broadcastDates.map((date) => (
                      <li key={date.year}>{returnHumanDate(date)}</li>
                    ))}
                  </ul>
                ) : (
                  `${returnSortedDateString(record.broadcastDates)}.`
                )}
              </li>
            )}
            {record.releaseDates && (
              <li data-testid="nac-description__dates--release">
                {`This ${returnIconAndLabelFromString(
                  record.levelOfDescription
                ).label.toLowerCase()} was released ${
                  record.releaseDates.length > 1
                    ? 'on the following dates:'
                    : record.releaseDates[0].day
                    ? 'on '
                    : 'in '
                }`}
                {record.releaseDates.length > 1 ? (
                  <ul>
                    {record.releaseDates.map((date) => (
                      <li key={date.year}>{returnHumanDate(date)}</li>
                    ))}
                  </ul>
                ) : (
                  `${returnSortedDateString(record.releaseDates)}.`
                )}
              </li>
            )}
            {record.copyrightDates && (
              <li data-testid="nac-description__dates--copyright">
                {`This ${returnIconAndLabelFromString(
                  record.levelOfDescription
                ).label.toLowerCase()}’s copyright was established ${
                  record.copyrightDates.length > 1
                    ? 'on the following dates:'
                    : record.copyrightDates[0].day
                    ? 'on '
                    : 'in '
                }`}
                {record.copyrightDates.length > 1 ? (
                  <ul>
                    {record.copyrightDates.map((date) => (
                      <li key={date.year}>{returnHumanDate(date)}</li>
                    ))}
                  </ul>
                ) : (
                  `${returnSortedDateString(record.copyrightDates)}.`
                )}
              </li>
            )}
            {record.inclusiveStartDate || record.inclusiveEndDate ? (
              <li data-testid="nac-description__dates--inclusive">
                {`The creator compiled or maintained this ${returnIconAndLabelFromString(
                  record.levelOfDescription
                ).label.toLowerCase()} between ${returnSortedDateString([
                  record.inclusiveStartDate,
                  record.inclusiveEndDate,
                ])}.
                  `}
              </li>
            ) : (
              record.ancestors &&
              record.ancestors.map((ancestor, i) => {
                return (
                  ancestor.levelOfDescription === 'series' && (
                    <li
                      data-testid={`nac-description__dates--ancestor-${i}`}
                      key={
                        ancestor.inclusiveStartDate.year +
                        ancestor.inclusiveEndDate.year
                      }
                    >
                      The creator compiled or maintained the parent series,{' '}
                      <Link to={`/id/${ancestor.naId}`}>{ancestor.title}</Link>,
                      between{' '}
                      {returnSortedDateString([
                        ancestor.inclusiveStartDate,
                        ancestor.inclusiveEndDate,
                      ])}
                      .
                    </li>
                  )
                )
              })
            )}
            {(record.coverageStartDate || record.coverageEndDate) && (
              <li data-testid="nac-description__dates--coverage">
                {`This ${returnIconAndLabelFromString(
                  record.levelOfDescription
                ).label.toLowerCase()} documents the time period of ${returnSortedDateString(
                  [record.coverageStartDate, record.coverageEndDate]
                )}.
                  `}
              </li>
            )}
            {record.dateNote && (
              <li>
                Note:&nbsp;
                <Highlighter search={q}>{record.dateNote}</Highlighter>
              </li>
            )}
          </ul>
        </Prose>
      )}

      {record.editStatus && (
        <Prose data-testid="nac-description__edit" title="Edit Status">
          {record.editStatus == 'Edited'
            ? `Audiovisual record(s) in this ${record.levelOfDescription} have been edited.`
            : record.editStatus == 'Partially Edited'
            ? `Audiovisual record(s) in this ${record.levelOfDescription} have been partially edited.`
            : `Audiovisual record(s) in this ${record.levelOfDescription} have not been edited.`}
        </Prose>
      )}

      {record.functionAndUse && (
        <Prose data-testid="nac-description__function" title="Function">
          <Highlighter search={q}>{record.functionAndUse}</Highlighter>
        </Prose>
      )}

      {record.generalNotes && record.generalNotes.length > 0 && (
        <Prose data-testid="nac-description__notes" title="General Notes">
          <ul
            className={[
              'add-list-reset',
              'list-with-margin',
              'line-height-sans-4',
            ].join(' ')}
          >
            {record.generalNotes.map((note) => (
              <li key={note} title="Note">
                <Prose>
                  <Highlighter search={q}>{note}</Highlighter>
                </Prose>
              </li>
            ))}
          </ul>
        </Prose>
      )}

      {record.numberingNote && (
        <Prose data-testid="nac-description__numbering" title="Numbering Note">
          <Highlighter search={q}>{record.numberingNote}</Highlighter>
        </Prose>
      )}

      {record.scaleNote && (
        <Prose data-testid="nac-description__scale" title="Scale">
          <Highlighter search={q}>{record.scaleNote}</Highlighter>
        </Prose>
      )}

      {record.transferNote && (
        <Prose data-testid="nac-description__transfer" title="Transfer Note">
          <Highlighter search={q}>{record.transferNote}</Highlighter>
        </Prose>
      )}

      {record.languages && record.languages.length > 0 && (
        <Prose data-testid="nac-description__language" title="Languages">
          <ul
            className={[
              'add-list-reset',
              'list-with-margin',
              'line-height-sans-4',
            ].join(' ')}
          >
            {record.languages.map((language) => (
              <li key={language}>
                <Prose>
                  <Highlighter search={q}>{language}</Highlighter>
                </Prose>
              </li>
            ))}
          </ul>
        </Prose>
      )}

      {record.soundType && (
        <Prose data-testid="nac-description__sound" title="Sound">
          {record.soundType === 'Sound'
            ? `Audiovisual record(s) in this ${record.levelOfDescription} have sound.`
            : `Audiovisual record(s) in this ${record.levelOfDescription} are silent.`}
        </Prose>
      )}
    </>
  )
}
SectionDescription.propTypes = {
  isPrinting: PropTypes.bool,
}

export default SectionDescription
