import React, { Fragment, useContext, useEffect, useMemo } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import { Icon } from '../Icon/Icon'
import {
  returnIconAndLabelFromString,
  splitOrgs,
} from '../../helper-functions/metadata-functions'
import SearchContext from '../../contexts/SearchContext'
import { IconLabel } from '../IconLabel/IconLabel'
import useHighlightList from '../../hooks/use-highlight-list'
import PropTypes from 'prop-types'

const SectionDonors = ({ isPrint, ...props }) => {
  const { record } = useContext(RecordContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const donors = useMemo(() => {
    if (record.donors) {
      return record.donors
    }
    return false
  }, [record])

  const { sortedArray, count } = useHighlightList(q, donors, ['heading'], 4)

  if (!donors) return false
  return (
    <div
      className={[
        'flex-column',
        donors && donors.length > 0
          ? [
              'border-right-0',
              'tablet-lg:border-base-lighter',
              'tablet-lg:border-right',
              'tablet-lg:margin-right-4',
              'tablet-lg:padding-right-4',
              'width-full',
            ].join(' ')
          : ['grid-col', 'tablet-lg:grid-col-6'].join(' '),
      ].join(' ')}
    >
      <SectionHeading copyLink count={length} {...props}>
        {length > 1 ? 'Donors' : 'Donor'}
      </SectionHeading>{' '}
      <div className={['display-flex', 'flex-column', 'width-full'].join(' ')}>
        <ul className={['add-list-reset', 'list-with-margin'].join(' ')}>
          <ExpandCollapseList
            gap="0"
            id="donors-list"
            initial={count}
            isPrint={isPrint}
          >
            {sortedArray.map((donor, i) => (
              <li
                key={donor.naId}
                data-testid={`nac-description__donors--${i}`}
              >
                <IconLabel
                  align="start"
                  iconSize="sm"
                  iconPercent="80"
                  iconName={
                    returnIconAndLabelFromString(donor.authorityType).icon
                  }
                >
                  <span>
                    {splitOrgs(donor, q)}
                    <span className="usa-sr-only">,</span>
                  </span>
                </IconLabel>
              </li>
            ))}
          </ExpandCollapseList>
        </ul>
      </div>
    </div>
  )
}
SectionDonors.propTypes = {
  isPrint: PropTypes.bool,
}
export default SectionDonors
