import React, { Fragment, useState, useRef, useEffect } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import useDetectPrinting from '../../hooks/use-detect-printing'

const ExpandCollapseList = ({
  children,
  disabled,
  flex,
  gap,
  id,
  initial,
  less,
  more,
  offset,
  progressive,
  isPrint,
  ...props
}) => {
  let newOffset = offset || initial / 2

  const ref = useRef(null)
  const [itemsToShow, setItemsToShow] = useState(initial)
  const [expanded, setExpanded] = useState(false)
  const { isPrinting } = useDetectPrinting()

  const handleShowMore = () => {
    if (!progressive) {
      itemsToShow === initial
        ? setItemsToShow(children.length)
        : setItemsToShow(initial)
      setExpanded(!expanded)
    }
  }

  if (!more) more = `show ${children.length - initial} more...`

  useEffect(() => {
    const item = ref.current
    if (expanded)
      item.children[initial]?.querySelector('a')?.focus() ||
        item.children[initial].focus()
  }, [itemsToShow])

  if (
    isPrint ||
    isPrinting ||
    children.length <= initial + newOffset ||
    disabled
  ) {
    return children
  } else {
    return (
      <div
        id={id}
        ref={ref}
        className={[
          flex ? 'display-flex flex-align-start flex-column' : '',
          `flex-gap${gap ? `-${gap}` : ''}`,
        ].join(' ')}

        // {`${
        //   flex ? 'display-flex flex-align-start flex-column' : ''
        // } ${gap ? 'flex-gap-' + gap : 'flex-gap'}`}
      >
        <span className="usa-sr-only">
          This list is {expanded ? 'expanded' : 'collapsed'},
        </span>
        {children.slice(0, itemsToShow).map((child, i) => (
          <Fragment key={Math.random()}>{child}</Fragment>
        ))}
        <div className="flex-row grid-row width-full">
          <button
            aria-controls={id}
            aria-label={`${expanded ? less : more}`}
            aria-expanded={expanded}
            onClick={handleShowMore}
            className={[
              'bg-transparent',
              'border-0',
              'clickable',
              'font-sans-3xs',
              'linkable',
              'padding-1',
              'text-primary',
            ].join(' ')}
          >
            {expanded ? less : more}
          </button>
        </div>
      </div>
    )
  }
}

ExpandCollapseList.defaultProps = {
  disabled: false,
  flex: true,
  gap: null,
  initial: 15, //number of list iems to display on load
  less: 'Show less...',
  offset: null, //if the number of remaining list items is less than this number, they will be displayed as opposed to requiring the user to click the "More" button
  progressive: false,
}

ExpandCollapseList.propTypes = {
  /**
   * JSX element(s), The content provided to the portal.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  disabled: PropTypes.bool,
  flex: PropTypes.bool,
  gap: PropTypes.oneOf(['0', 'sm', 'md', 'lg']),
  id: PropTypes.string,
  initial: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  offset: PropTypes.number,
  progressive: PropTypes.bool,
  isPrint: PropTypes.bool,
}

export default ExpandCollapseList
