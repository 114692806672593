import React, { Fragment, useContext, useMemo } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import { Icon } from '../Icon/Icon'
import { SectionHeading } from '../SectionHeading/SectionHeading'
//Helpers
import {
  returnIconAndLabelFromString,
  splitOrgs,
} from '../../helper-functions/metadata-functions'
import {
  compareStrings,
  sortByHeadingAndDate,
} from '../../helper-functions/sort-functions'
import useDetectPrinting from '../../hooks/use-detect-printing'
import SearchContext from '../../contexts/SearchContext'
import { IconLabel } from '../IconLabel/IconLabel'
import useHighlightList from '../../hooks/use-highlight-list'
import PropTypes from 'prop-types'
/**
 * The people or organizations responsible for the creation, accumulation, or maintenance
 * of the series during its primary use. This information gives insight into the origin of the archival materials.
 * Note that lower level records (file units and items) inherit this information from their parent series.
 */
const SectionCreators = ({ isPrint, ...props }) => {
  console.log(props, 'prob')
  const { record } = useContext(RecordContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const creators = useMemo(() => {
    let creatorsArray = []
    // let length = 0
    if (record.creators) {
      // length = record.creators.length
      creatorsArray = Object.values(
        record.creators
          .sort((a, b) => sortByHeadingAndDate(a, b))
          .sort((a, b) => {
            return compareStrings(a.creatorType, b.creatorType)
          })
          .reduce((creator, x) => {
            creator[x.creatorType] = [...(creator[x.creatorType] || []), x]
            return creator
          }, {})
      )
    } else if (record.ancestors) {
      record.ancestors.map((ancestor) => {
        if (ancestor.creators) {
          // length = ancestor.creators.length
          creatorsArray = Object.values(
            ancestor.creators
              .sort((a, b) => sortByHeadingAndDate(a, b))
              .sort((a, b) => {
                return compareStrings(a.creatorType, b.creatorType)
              })
              .reduce((creator, x) => {
                creator[x.creatorType] = [...(creator[x.creatorType] || []), x]
                return creator
              }, {})
          )
        }
      })
    }
    if (creatorsArray?.length > 0) return creatorsArray
    else return false
  }, [record])
  const { sortedArray, count } = useHighlightList(
    q,
    creators?.[1],
    ['heading'],
    1,
    false
  )

  if (!creators) return false

  const { isPrinting } = useDetectPrinting()
  return (
    <div
      className={[
        'flex-column',
        record.contributors && record.contributors?.length > 0
          ? [
              'border-right-0',
              'margin-bottom-4',
              'padding-bottom-4',
              'tablet-lg:border-base-lighter',
              'tablet-lg:border-right',
              'tablet-lg:margin-right-4',
              'tablet-lg:padding-right-4',
              'width-full',
            ].join(' ')
          : ['grid-col', 'tablet-lg:grid-col-6'].join(' '),
      ].join(' ')}
    >
      <SectionHeading copyLink count={creators?.length} {...props}>
        {creators?.length > 1 ? 'Creators' : 'Creator'}
      </SectionHeading>{' '}
      <div
        className={[
          'display-flex',
          'flex-column',
          'flex-gap-sm',
          'width-full',
        ].join(' ')}
      >
        {creators.map((creator, i) => (
          <div
            key={Math.random()}
            data-testid={`nac-description__creators--${
              i === 0 ? 'most-recent' : 'predecessors'
            }`}
          >
            <h3
              id="creator-label"
              className={[
                'dispay-flex',
                'flex-align-center',
                'flex-gap-sm',
                'flex-no-wrap',
                'flex-row',
                'grid-row',
              ].join(' ')}
            >
              <span>
                {i === 0 ? (
                  'Most Recent'
                ) : (
                  <>
                    {creator[1] && creator[1]?.length > 1 ? (
                      <>
                        Predecessors
                        <span className="usa-sr-only">
                          . This section contains {creator[1]?.length} items
                        </span>
                      </>
                    ) : (
                      'Predecessor'
                    )}
                  </>
                )}
              </span>

              <span className="usa-sr-only">,</span>
            </h3>
            <ul className={['add-list-reset', 'list-with-margin'].join(' ')}>
              {i === 0 ? (
                <li key={creator[0].naId}>
                  <IconLabel
                    align="start"
                    iconSize="sm"
                    iconPercent="80"
                    iconName={
                      returnIconAndLabelFromString(creator[0].authorityType)
                        .icon
                    }
                  >
                    <span>
                      {splitOrgs(creator[0], q)}
                      <span className="usa-sr-only">,</span>
                    </span>
                  </IconLabel>
                </li>
              ) : (
                sortedArray?.length > 0 && (
                  <ExpandCollapseList
                    gap="0"
                    id="creators-list"
                    initial={count}
                    disabled={isPrinting}
                    isPrint={isPrint}
                  >
                    {sortedArray?.map((c) => (
                      <li key={c.naId}>
                        <IconLabel
                          align="start"
                          iconSize="sm"
                          iconPercent="80"
                          iconName={
                            returnIconAndLabelFromString(c.authorityType).icon
                          }
                        >
                          <span>
                            {splitOrgs(c, q)}
                            <span className="usa-sr-only">,</span>
                          </span>
                        </IconLabel>
                      </li>
                    ))}
                  </ExpandCollapseList>
                )
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
SectionCreators.propTypes = {
  isPrint: PropTypes.bool,
}
export default SectionCreators

const creatorsTypeAuthorityList = [
  {
    type: 'Most Recent',
    description:
      "When the creator is the one last responsible for the archival materials' creation, accumulation, or maintenance, they are considered the Most Recent",
  },
  {
    type: 'Predecessor',
    description:
      "When the creator is the one first or previously responsible for the archival materials' creation, accumulation, or maintenance, they are considered a predecessor",
  },
]
