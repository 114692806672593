import React, { Fragment, useContext, useMemo } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import Tooltip from '../Tooltip/Tooltip'
import { Icon } from '../Icon/Icon'
import { SectionHeading } from '../SectionHeading/SectionHeading'
//Helpers
import {
  returnIconAndLabelFromString,
  splitOrgs,
} from '../../helper-functions/metadata-functions'
import { compareStrings } from '../../helper-functions/sort-functions'
import SearchContext from '../../contexts/SearchContext'
import { IconLabel } from '../IconLabel/IconLabel'
import useHighlightList from '../../hooks/use-highlight-list'

const SectionContributors = ({ isPrint, ...props }) => {
  const { record } = useContext(RecordContext) || {}

  const contributorsArray = useMemo(() => {
    let output = []
    let map = []
    if (record.contributors) {
      map = new Map(
        record.contributors.map(({ contributorType }) => [
          contributorType,
          { contributorType, contributors: [], description: '' },
        ])
      )
      record.contributors.forEach((contributor, i) => {
        map.get(contributor.contributorType).contributors.push(contributor)
      })
    }
    let result = Array.from(map.values())
    result.map((item) => {
      return item.contributors.sort((a, b) =>
        a.heading.localeCompare(b.heading)
      )
    })

    output = result
    output.sort((a, b) => {
      return compareStrings(a.contributorType, b.contributorType)
    })

    output.map((item) =>
      contributorTypeAuthorityList.some(({ type, description }) => {
        if (item.contributorType === type) item.description = description
      })
    )

    if (output.length === 0) return false
    return output
  }, [record])

  const count = useMemo(() => {
    let c = 0
    if (!contributorsArray) return c
    contributorsArray.forEach((item) => {
      c += item.contributors.length
    })
    return c
  }, [contributorsArray])

  return (
    <div className={['flex-column', 'width-full'].join(' ')}>
      <SectionHeading copyLink count={count} {...props}>
        {record.contributors.length > 1 ? 'Contributors' : 'Contributor'}
      </SectionHeading>{' '}
      <div
        className={[
          'display-flex',
          'flex-column',
          'flex-gap-md',
          'width-full',
        ].join(' ')}
      >
        {contributorsArray.map((contributor, i) => (
          <div
            key={contributor.heading + ' ' + contributor.contributorType}
            // data-testid={`nac-description__contributors--${contributor.contributorType
            //   .toLowerCase()
            //   .replaceAll(' ', '-')}`}
            data-testid={
              contributor.contributorType
                ? `nac-description__contributors--${contributor.contributorType
                    .toLowerCase()
                    .replaceAll(' ', '-')}`
                : contributor.contributorType
            }
          >
            <h3
              id="contributor-label"
              className={[
                'dispay-flex',
                'flex-align-center',
                'flex-gap-sm',
                'flex-no-wrap',
                'flex-row',
                'grid-row',
              ].join(' ')}
            >
              <span>
                {contributor.contributors.length > 1 ? (
                  <>
                    {contributor.contributorType}s
                    <span className="usa-sr-only">
                      . This section contains {contributor.contributors.length}{' '}
                      items
                    </span>
                  </>
                ) : (
                  contributor.contributorType
                )}
              </span>
              {/*contributor.description && (
                <span>
                  <Tooltip
                    content={contributor.description}
                    delay={500}
                    position="bottomCenter"
                  >
                    <Icon
                      iconPercent="80"
                      color="base-dark"
                      iconName="circle-questions"
                      iconSize="sm"
                    />
                  </Tooltip>
                </span>
              )*/}
              <span className="usa-sr-only">,</span>
            </h3>
            <Contributor array={contributor.contributors} isPrint={isPrint} />
          </div>
        ))}
      </div>
    </div>
  )
}
SectionContributors.propTypes = {
  isPrint: PropTypes.bool,
}
export default SectionContributors

export const Contributor = ({ isPrint, array, ...props }) => {
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  const { sortedArray, count } = useHighlightList(q, array, ['heading'], 3)
  if (!sortedArray) return false
  return (
    <ul className={['add-list-reset', 'list-with-margin'].join(' ')}>
      <ExpandCollapseList
        gap="0"
        id="contributors-list"
        initial={count}
        isPrint={isPrint}
      >
        {sortedArray.map((cont, j) => (
          <li key={cont.naId}>
            <IconLabel
              align="start"
              iconSize="sm"
              iconPercent="80"
              iconName={returnIconAndLabelFromString(cont.authorityType).icon}
            >
              <span>
                <span>{splitOrgs(cont, q)}</span>
                <span className="usa-sr-only">,</span>
              </span>
            </IconLabel>
          </li>
        ))}
      </ExpandCollapseList>
    </ul>
  )
}

Contributor.propTypes = {
  array: PropTypes.array,
  isPrint: PropTypes.bool,
}

const contributorTypeAuthorityList = [
  {
    type: 'Actor',
    description:
      'A person who principally exhibits acting skills in a musical or dramatic presentation or entertainment.',
  },
  {
    type: 'Adapter',
    description:
      'A person who 1) reworks a musical composition, usually for a different medium, or 2) rewrites novels or stories for motion pictures or other audiovisual medium.',
  },
  {
    type: 'Annotator',
    description:
      'A person who writes manuscript annotations on a printed item.',
  },
  {
    type: 'Arranger',
    description:
      'A person who transcribes a musical composition, usually for a different medium from that of the original; in an arrangement the musical substance remains essentially unchanged.',
  },
  {
    type: 'Artist',
    description:
      'A person (e.g., a painter) who conceives, and perhaps also implements, an original graphic design or work of art, if specific terms (e.g., Engraver, Etcher) are not desired. For book illustrators, prefer Illustrator.',
  },
  {
    type: 'Assignee',
    description:
      'A person or organization to whom a license for printing or publishing has been transferred.',
  },
  {
    type: 'Associated name',
    description:
      'General term for a name associated with or found in an item or collection, or which cannot be determined to be that of a Former owner or other designated term indicative of provenance.',
  },
  {
    type: 'Attributed name',
    description:
      'Relates an author, artist, etc. to a work for which there is or once was substantial authority for designating that person as author, creator, etc. of the work.',
  },
  {
    type: 'Auctioneer',
    description:
      'A person or corporate body in change or the estimation and public auctioning of goods, particularly books, artistic works, etc.',
  },
  {
    type: 'Author',
    description:
      'A person or corporate body chiefly responsible for the intellectual or artistic content of a work, usually printed text. This term may also be used when more than one person or body bears such responsibility.',
  },
  {
    type: 'Author in quotations or text extracts',
    description:
      'A person whose work is largely quoted or extracted in a works to which he or she did not contribute directly. Such quotations are found particularly in exhibition catalogs, collections of photographs, etc.',
  },
  {
    type: 'Author of afterword, colophon, etc.',
    description:
      'A person or corporate body responsible for an afterword, postface, colophon, etc. but who is not the chief author of a work.',
  },
  {
    type: 'Author of introduction, etc.',
    description:
      'A person or corporate body responsible for an introduction, preface, foreword, or other critical introductory matter, but who is not the chief author.',
  },
  {
    type: 'Author of screenplay, etc.',
    description:
      'A person or corporate body responsible for a motion picture screenplay, dialog, spoken commentary, etc.',
  },
  {
    type: 'Bibliographic antecedent',
    description:
      'The author responsible for a work upon which the work represented by the catalog record is based. This may be appropriate for adaptations, sequels, continuations, indexes, etc.',
  },
  {
    type: 'Book designer',
    description:
      'The person or firm responsible for the entire graphic design of a book, including arrangement of type and illustration, choice of materials, and process used.',
  },
  {
    type: 'Book producer',
    description:
      'The person or firm responsible for the production of books and other print media, if specific terms (e.g., Book designer, Engraver, Type designer, Printer) are not desired.',
  },
  {
    type: 'Censor',
    description:
      'A censor, bowdlerizer, expurgator, etc., official or private.',
  },
  {
    type: 'Choreographer',
    description:
      'A person who composes or arranges dances or other movements (e.g., "master of swords") for a musical or dramatic presentation or entertainment.',
  },
  {
    type: 'Client',
    description:
      'A person or organization for whom another person or organization is acting.',
  },
  {
    type: 'Collaborator',
    description:
      'A person or corporate body that takes a limited part in the elaboration of a work of another author or that brings complements (e.g., appendices, notes) to the work of another author.',
  },
  {
    type: 'Collector',
    description:
      'A person who has brought together material from various sources, which has been arranged, described, and cataloged as a collection. The collector is neither the creator of the material nor the person to whom manuscripts in the collection may have been addressed.',
  },
  {
    type: 'Commentator',
    description:
      'A person who provides interpretation, analysis, or a discussion of the subject matter on a recording, motion picture, or other audiovisual medium.',
  },
  {
    type: 'Compiler',
    description:
      'A person who produces a work or publication by selecting and putting together material from the works of various persons or bodies.',
  },
  {
    type: 'Complainant',
    description:
      'The party who applies to the courts for redress, usually in an equity proceeding.',
  },
  {
    type: 'Complainant-appellant',
    description:
      'A complainant who takes an appeal from one court or jurisdiction to another to reverse the judgment, usually in an equity proceeding.',
  },
  {
    type: 'Complainant-appellee',
    description:
      'A complainant against whom an appeal is taken from one court or jurisdiction to another to reverse the judgment, usually in an equity proceeding.',
  },
  {
    type: 'Composer',
    description:
      'A person who creates a musical work, usually a piece of music in manuscript or printed form.',
  },
  {
    type: 'Conceptor',
    description:
      'A person or corporate body responsible for the original idea on which a work is based, this includes the scientific author of an audio-visual item and the conceptor of an advertisement.',
  },
  {
    type: 'Conductor',
    description:
      'A person who directs a performing group (orchestra, chorus, opera, etc.).',
  },
  {
    type: 'Consultant',
    description:
      'The person called upon for professional advice or services in a specialized field of knowledge or training.',
  },
  {
    type: 'Consultant to a project',
    description:
      'A person or corporate body engaged specifically to provide an intellectual overview of a strategic or operational task and by analysis, specification, or instruction, to create or propose a cost-effective course of action or solution.',
  },
  {
    type: 'Contestant',
    description:
      'The party who opposes, resists, or disputes, in a court of law, a claim, decision, result, etc.',
  },
  {
    type: 'Contestant-appellant',
    description:
      'A contestant who takes an appeal from one court of law or jurisdiction to another to reverse the judgment.',
  },
  {
    type: 'Contestant-appellee',
    description:
      'A contestant against whom an appeal is taken from one court of law or jurisdiction to another to reverse the judgment.',
  },
  {
    type: 'Contestee',
    description:
      'The party defending a claim, decision, result, etc. being opposed, resisted, or disputed in a court of law.',
  },
  {
    type: 'Contestee-appellant',
    description:
      'A contestee who takes an appeal from one court or jurisdiction to another to reverse the judgment.',
  },
  {
    type: 'Contestee-appellee',
    description:
      'A contestee against whom an appeal is taken from one court or jurisdiction to another to reverse the judgment.',
  },
  {
    type: 'Contractor',
    description:
      'The person or corporate body who enters into a contract with another person or corporate body to perform a specific task.',
  },
  {
    type: 'Corrector',
    description:
      'A corrector of manuscripts, such as the scriptorium official who corrected the work of a scribe. For printed matter, use Proofreader.',
  },
  {
    type: 'Correspondent',
    description:
      'A person or organization who was either the writer or recipient of a letter or other communication.',
  },
  {
    type: 'Costume designer',
    description:
      'A person who designs or makes costumes, fixes hair, etc., for a musical or dramatic presentation or entertainment.',
  },
  {
    type: 'Curator of an exhibition',
    description:
      'A person who is responsible for conceiving and organizing an exhibition.',
  },
  {
    type: 'Dancer',
    description:
      'A person who principally exhibits dancing skills in a musical or dramatic presentation or entertainment.',
  },
  {
    type: 'Dedicatee',
    description:
      'A person or organization to whom a book, manuscript, etc., is dedicated (not the recipient of a gift).',
  },
  {
    type: 'Dedicator',
    description:
      'The author of a dedication, which may be a formal statement or in epistolary or verse form.',
  },
  {
    type: 'Defendant',
    description:
      'The party defending or denying allegations made in a suit and against whom relief or recovery is sought in the courts, usually in a legal action.',
  },
  {
    type: 'Defendant-appellant',
    description:
      'A defendant who takes an appeal from one court or jurisdiction to another to reverse the judgment, usually in a legal action.',
  },
  {
    type: 'Defendant-appellee',
    description:
      'A defendant against whom an appeal is taken from one court or jurisdiction to another to reverse the judgment, usually in a legal action.',
  },
  {
    type: 'Delineator',
    description:
      "A person or organization executing technical drawings from others' designs.",
  },
  {
    type: 'Depositor',
    description:
      'A person or organization placing material in the physical custody of a library or repository without transferring the legal title.',
  },
  {
    type: 'Designer',
    description:
      'A person or organization responsible for design if specific codes (e.g., Book designer, Type designer) are not desired.',
  },
  {
    type: 'Director',
    description:
      'A person who is responsible for the general management of a work or who supervises the production of a performance for stage, screen, or sound recording.',
  },
  {
    type: 'Dissertant',
    description:
      'A person who presents a thesis for a university or higher-level educational degree.',
  },
  {
    type: 'Distributor',
    description:
      'An agent or agency that has exclusive or shared marketing rights for an item.',
  },
  {
    type: 'Dubious author',
    description:
      'A person or corporate body to which authorship has been dubiously or incorrectly ascribed.',
  },
  {
    type: 'Editor',
    description:
      'A person who prepares for publication a work not primarily his/her own, such as by elucidating text, adding introductory or other critical matter, or technically directing an editorial staff.',
  },
  {
    type: 'Engineer',
    description:
      'A person or organization that is responsible for technical planning and design, particularly with construction.',
  },
  {
    type: 'Expert',
    description:
      'A person in charge of the description and appraisal of the value of goods, particularly rare items, works of art, etc.',
  },
  {
    type: 'Film editor',
    description:
      'An editor of a motion picture film. This term is used regardless of the medium upon which the motion picture is produced or manufactured (e.g., acetate film, video tape).',
  },
  {
    type: 'Former owner',
    description:
      'The person or organization who owned an item at any time in the past. Includes those to whom the material was once presented. The person or organization giving the item to the present owner is designated as Donor.',
  },
  {
    type: 'Funder',
    description:
      'The person or agency that furnished financial support for the production of the work.',
  },
  {
    type: 'Honoree',
    description:
      'The person in memory or honor of whom a book, manuscript, etc. is donated.',
  },
  {
    type: 'Host',
    description:
      'The person who is invited or regularly leads a program (often broadcast) that includes other guests, performers, etc. (e.g., talk show host).',
  },
  {
    type: 'Illustrator',
    description:
      'The person who conceives, and perhaps also implements, a design or illustration, usually to accompany a written text.',
  },
  {
    type: 'Inscriber',
    description: 'The person who signs a presentation statement.',
  },
  {
    type: 'Inspector',
    description:
      'A person who conducts an inspection or otherwise ascertains the state of affairs.',
  },
  {
    type: 'Instrumentalist',
    description:
      'A person who principally plays an instrument in a musical or dramatic presentation or entertainment.',
  },
  {
    type: 'Judge',
    description:
      'A public official appointed or elected to hear and decide legal matters in court.',
  },
  {
    type: 'Lender',
    description:
      'A person or organization permitting the temporary use of a book, manuscript, etc., such as for photocopying or microfilming.',
  },
  {
    type: 'Libelant',
    description:
      'The party who files a libel in an ecclesiastical or admiralty case.',
  },
  {
    type: 'Libelant-appellant',
    description:
      'A libelant who takes an appeal from one ecclesiastical court or admiralty to another to reverse the judgment.',
  },
  {
    type: 'Libelant-appellee',
    description:
      'A libelant against whom an appeal is taken from one ecclesiastical court or admiralty to another to reverse the judgment.',
  },
  {
    type: 'Libelee',
    description:
      'The party against whom a libel has been filed in an ecclesiastical court or admiralty.',
  },
  {
    type: 'Libelee-appellant',
    description:
      'A libelee who takes an appeal from one ecclesiastical court or admiralty to another to reverse the judgment.',
  },
  {
    type: 'Libelee-appellee',
    description:
      'A libelee against whom an appeal is taken from one ecclesiastical court or admiralty to another to reverse the judgment.',
  },
  {
    type: 'Librettist',
    description: 'The writer of the text of an opera, oratorio, etc.',
  },
  {
    type: 'Licensee',
    description: 'The original recipient of the right to print or publish.',
  },
  {
    type: 'Licensor',
    description: 'The signer of the license, imprimatur, etc.',
  },
  {
    type: 'Lithographer',
    description:
      'The person who prepares the stone or plate for lithographic printing, including a graphic artist creating a design directly on the surface from which printing will be done.',
  },
  {
    type: 'Lyricist',
    description: 'The writer of the text of a song.',
  },
  {
    type: 'Manufacture place',
    description:
      'The name of the place of manufacture (e.g., printing, duplicating, casting, etc.) of a resource in a published form.',
  },
  {
    type: 'Manufacturer',
    description:
      'A person or organization that makes an artifactual work (an object made or modified by one or more persons). Examples of artifactual works include vases, cannons or pieces of furniture.',
  },
  {
    type: 'Metadata contact',
    description:
      'The person or organization primarily responsible for compiling and maintaining the original description of a metadata set (e.g., geospatial metadata set).',
  },
  {
    type: 'Moderator',
    description:
      'The person who leads a program (often broadcast) where topics are discussed, usually with participation of experts in fields related to the discussion.',
  },
  {
    type: 'Monitor',
    description:
      'A person or organization that supervises compliance with the contract and is responsible for the report and controls its distribution. Sometimes referred to as the grantee, or controlling agency.',
  },
  {
    type: 'Musician',
    description:
      'The person who performs music or contributes to the musical content of a work when it is not possible or desirable to identify the function more precisely.',
  },
  {
    type: 'Narrator',
    description:
      'The speaker who relates the particulars of an act, occurrence, or course of events.',
  },
  {
    type: 'Organizer of meeting',
    description:
      'The person or corporate body responsible for organizing a meeting for which an item is the report or proceedings.',
  },
  {
    type: 'Originator',
    description:
      'The name of a person or organization associated with the intellectual content of the work.',
  },
  {
    type: 'Participant',
    description:
      'A person who participates in or contributes to a council or meeting but who may not be the subject of the meeting.',
  },
  {
    type: 'Patron',
    description:
      'The person responsible for commissioning a work. Usually a patron uses his or her means or influence to support the work of artists, writers, etc. This includes those who commission and pay for individual works.',
  },
  {
    type: 'Performer',
    description:
      'A person who exhibits musical or acting skills in a musical or dramatic presentation or entertainment, if specific terms for those functions (Actor, Dancer, Instrumentalist, Vocalist, etc.) are not used. If specific terms are used, Performer is used for a person whose principal skill is not known or specified.',
  },
  {
    type: 'Photographer',
    description:
      'The person or organization responsible for taking photographs, whether they are used in their original form or as reproductions.',
  },
  {
    type: 'Plaintiff',
    description:
      'The party who complains or sues in court in a personal action, usually in a legal proceeding.',
  },
  {
    type: 'Plaintiff-appellant',
    description:
      'A plaintiff who takes an appeal from one court or jurisdiction to another to reverse the judgment, usually in a legal proceeding.',
  },
  {
    type: 'Plaintiff-appellee',
    description:
      'A plaintiff against whom an appeal is taken from one court or jurisdiction to another to reverse the judgment, usually in a legal proceeding.',
  },
  {
    type: 'Printer',
    description:
      'The person or organization who prints texts, whether from type or plates.',
  },
  {
    type: 'Printer of Plates',
    description:
      'The person or organization who prints illustrations from plates.',
  },
  {
    type: 'Printmaker',
    description:
      'The person who makes a relief, intaglio, or planographic printing surface.',
  },
  {
    type: 'Process contact',
    description:
      'A person or organization primarily responsible for performing or initiating a process, such as is done with the collection of metadata sets.',
  },
  {
    type: 'Producer',
    description:
      'A person who is responsible for the making of a motion picture, including business aspects, management of the productions, and the commercial success of the work.',
  },
  {
    type: 'Production personnel',
    description:
      'A person who is associated with the production (props, lighting, special effects, etc.) of a musical or dramatic presentation or entertainment.',
  },
  {
    type: 'Programmer',
    description:
      'A person or corporate body responsible for the creation and/or maintenance of computer program design documents, source code, and machine-executable digital files and supporting documentation.',
  },
  {
    type: 'Proofreader',
    description:
      'A person who corrects printed matter. For manuscripts, use Corrector.',
  },
  {
    type: 'Publishing director',
    description:
      'A person who presides over the elaboration of a collective work to ensure its coherence or continuity. This includes editors-in-chief, literary editors, editors of series, etc.',
  },
  {
    type: 'Recipient',
    description: 'The person to whom a correspondence is addressed.',
  },
  {
    type: 'Recording engineer',
    description:
      'A person who supervises the technical aspects of a sound or video recording session.',
  },
  {
    type: 'Redactor',
    description:
      'A person who writes or develops the framework for an item without being intellectually responsible for its content.',
  },
  {
    type: 'Research team head',
    description:
      'The person or corporate body that directed or managed a research project.',
  },
  {
    type: 'Research team member',
    description:
      'The person or corporate body that participated in a research project but whose role did not involve direction or management of it.',
  },
  {
    type: 'Researcher',
    description:
      'The person or corporate body responsible for performing research.',
  },
  {
    type: 'Respondent',
    description:
      'The party who makes an answer to the courts pursuant to an application for redress, usually in an equity proceeding.',
  },
  {
    type: 'Respondent-appellant',
    description:
      'A respondent who takes an appeal from one court or jurisdiction to another to reverse the judgment, usually in an equity proceeding.',
  },
  {
    type: 'Respondent-appellee',
    description:
      'A respondent against whom an appeal is taken from one court or jurisdiction to another to reverse the judgment, usually in an equity proceeding.',
  },
  {
    type: 'Reviewer',
    description:
      'A person or corporate body responsible for the review of book, motion picture, performance, etc.',
  },
  {
    type: 'Scenarist',
    description: 'The author of a motion picture screenplay.',
  },
  {
    type: 'Scientific advisor',
    description:
      'A person who brings scientific, pedagogical, or historical competence to the conception and realization on a work, particularly in the case of audio-visual items.',
  },
  {
    type: 'Scribe',
    description:
      'An amanuensis and for a writer of manuscripts proper. For a person who makes pen-facsimiles, use Facsimilist.',
  },
  {
    type: 'Secretary',
    description:
      'A recorder, redactor, or other person responsible for expressing the views of a corporate body.',
  },
  {
    type: 'Signer',
    description:
      'The person whose signature appears without a presentation or other statement indicative of provenance. When there is a presentation statement, use Inscriber.',
  },
  {
    type: 'Singer',
    description:
      "A person who uses his or her voice with or without instrumental accompaniment to produce music. A singer's performance may or may not include actual words.",
  },
  {
    type: 'Speaker',
    description:
      'A person who participates in a program (often broadcast) and makes a formalized contribution or presentation generally prepared in advance.',
  },
  {
    type: 'Sponsor',
    description:
      'The person or agency that issued a contract or under the auspices of which a work has been written, printed, published, etc.',
  },
  {
    type: 'Surveyor',
    description:
      'A person or organization who does measurements of tracts of land, etc. to determine location, forms, and boundaries.',
  },
  {
    type: 'Thesis advisor',
    description:
      'The person under whose supervision a degree candidate develops and presents a thesis, mémoire, or text of a dissertation.',
  },
  {
    type: 'Transcriber',
    description:
      'A person who prepares a handwritten or typewritten copy from original material, including from dictated or orally recorded material. For makers of pen-facsimiles, use Facsimilist.',
  },
  {
    type: 'Translator',
    description:
      'A person who renders a text from one language into another, or from an older form of a language into the modern form.',
  },
  {
    type: 'Type designer',
    description:
      'The person who designed the type face used in a particular item.',
  },
  {
    type: 'Typographer',
    description:
      'The person primarily responsible for choice and arrangement of type used in an item. If the typographer is also responsible for other aspects of the graphic design of a book (e.g., Book designer), codes for both functions may be needed.',
  },
  {
    type: 'Vocalist',
    description:
      'A person who principally exhibits singing skills in a musical or dramatic presentation or entertainment.',
  },
  {
    type: 'Writer of accompanying material',
    description:
      'A person who writes significant material which accompanies a sound recording or other audiovisual material.',
  },
]
