import React, { Fragment, useContext, useMemo } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import { compareStrings } from '../../helper-functions/sort-functions'
import { SectionHeading } from '../SectionHeading/SectionHeading'
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import Prose from '../utilities/Prose'
import useOrganizations from '../Pages/AuthorityPages/use_organizations'
import useHighlightList from '../../hooks/use-highlight-list'
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'

const SectionControlNumbers = ({ isPrint, ...props }) => {
  const recordContext = useContext(RecordContext)
  const record = recordContext.record

  // If we're on an organization page, get the IDs of all the org names
  let childOrgNaIds = []
  if (
    record.authorityType === 'organizationName' ||
    record.authorityType === 'organization'
  ) {
    let organization = record
    if (organization.parent) organization = organization.parent
    organization.organizationNames.map((name) => {
      childOrgNaIds.push(name.naId)
    })
  }

  const numbersArray = useMemo(() => {
    let output = []
    let map = []
    if (record.variantControlNumbers) {
      map = new Map(
        record.variantControlNumbers.map(({ type, note }) => [
          type,
          { type, numbers: [], note: note || '', description: '' },
        ])
      )
      record.variantControlNumbers.forEach((o, i) => {
        if (typeof o.number == 'string') {
          map.get(o.type).numbers.push({ number: o.number, note: o.note || '' })
          //Remove the top-level note if one exists for a number from the numbers array
          if (o.note) map.get(o.type).note = ''
        }
      })
    }
    let result = Array.from(map.values())
    result.map((item) => {
      return item.numbers.sort((a, b) => a.number.localeCompare(b.number))
    })

    output = result
    if (childOrgNaIds.length > 0) {
      output.push({
        numbers: childOrgNaIds,
        type: 'Organization Name NAID',
      })
    }
    if (record.importRecordControlNumber)
      output.push({
        numbers: [record.importRecordControlNumber],
        type: 'Import Control Number',
      })
    if (record.localIdentifier)
      output.push({
        numbers:
          record.localIdentifier.split('; ').length > 1
            ? record.localIdentifier.split('; ')
            : [record.localIdentifier],
        type: 'Local Identifier',
      })
    if (record.accessionNumbers)
      output.push({
        numbers: record.accessionNumbers.sort((a, b) => a.localeCompare(b)),
        type: 'Accession Number',
      })
    if (record.internalTransferNumbers)
      output.push({
        numbers: record.internalTransferNumbers.sort((a, b) =>
          a.localeCompare(b)
        ),
        type: 'Internal Transfer Number',
      })
    if (record.dispositionAuthorityNumbers)
      output.push({
        numbers: record.dispositionAuthorityNumbers.sort((a, b) =>
          a.localeCompare(b)
        ),
        type: 'Disposition Authority Number',
      })
    if (record.recordsCenterTransferNumbers)
      output.push({
        numbers: record.recordsCenterTransferNumbers.sort((a, b) =>
          a.localeCompare(b)
        ),
        type: 'Records Center Transfer Number',
      })
    output.sort((a, b) => {
      return compareStrings(a.type, b.type)
    })
    if (record.naId)
      output.unshift({
        numbers: [{ number: String(record.naId) }],
        type: 'National Archives Identifier',
      })

    output.map((item) =>
      controlNumberDescriptions.some(({ type, description }) => {
        if (item.type === type) item.description = description
      })
    )

    if (output.length === 0) return false
    return output
  }, [record])

  //The total number of control numbers
  //Count individual numbers of the same type
  const count = useMemo(() => {
    let c = 0
    if (!numbersArray) return c
    numbersArray.forEach((item) => {
      c += item.numbers.length
    })
    return c
  }, [numbersArray])

  if (!numbersArray) return false
  return (
    <div className="width-full">
      <SectionHeading copyLink count={count} {...props}>
        {numbersArray.length > 1 ? 'Control Numbers' : 'Control Number'}
      </SectionHeading>{' '}
      <div
        className={[
          'display-flex',
          'flex-column',
          'flex-gap-md',
          'width-full',
        ].join(' ')}
      >
        <ExpandCollapseList gap="sm" isPrint={isPrint}>
          {numbersArray.map((num, i) => (
            <div
              key={num.type + ' ' + i}
              // data-testid={`nac-description__control-numbers--${num.type
              //   .toLowerCase()
              //   .replaceAll('/', '')
              //   .replaceAll(' ', '-')}`}
              data-testid={
                num.type
                  ? `nac-description__control-numbers--${num.type
                      .toLowerCase()
                      .replaceAll('/', '')
                      .replaceAll(' ', '-')}`
                  : num.type
              }
            >
              <h3
                id={`controlNumbers-label-${i}`}
                className={[
                  'dispay-flex',
                  'flex-align-center',
                  'flex-gap-sm',
                  'flex-no-wrap',
                  'flex-row',
                  'grid-row',
                  'width-full',
                ].join(' ')}
              >
                <span>
                  {num.numbers.length > 1 ? (
                    <>
                      {num.type}s
                      <span className="usa-sr-only">
                        . This section contains {num.numbers.length} items
                      </span>
                    </>
                  ) : (
                    num.type
                  )}
                </span>

                {/*num.description && (
                  <span>
                    <Tooltip
                      content={num.description}
                      delay={500}
                      position="bottomCenter"
                    >
                      <Icon
                        iconPercent="80"
                        color="base-dark"
                        iconName="circle-questions"
                        iconSize="sm"
                      />
                    </Tooltip>
                  </span>
                )*/}
                <span className="usa-sr-only">,</span>
              </h3>
              <Number array={num.numbers} index={i} isPrint={isPrint} />
            </div>
          ))}
        </ExpandCollapseList>
      </div>
    </div>
  )
}
SectionControlNumbers.propTypes = {
  isPrint: PropTypes.bool,
}

export default SectionControlNumbers

export const Number = ({ isPrint, array, index, ...props }) => {
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  const { sortedArray, count } = useHighlightList(
    q,
    array,
    array[0]?.number ? ['number', 'note'] : null,
    5
  )
  if (!sortedArray) return false
  return (
    <ul
      className={[
        'add-list-reset',
        'display-flex',
        'flex-column',
        'flex-gap-sm',
        'width-full',
      ].join(' ')}
    >
      <ExpandCollapseList gap="sm" initial={count} isPrint={isPrint}>
        {sortedArray.map((num, j) => (
          <li key={num.number + ' ' + index + ' ' + j} className="width-full">
            <span className="display-block width-full">
              <Highlighter search={q}>{num.number || num}</Highlighter>
              {num.note && (
                <span
                  aria-hidden={true}
                  className={['font-sans-3xs', 'text-italic'].join(' ')}
                >
                  *
                </span>
              )}
            </span>
            {num.note && (
              <Prose
                className={['font-sans-3xs', 'text-italic'].join(' ')}
                maxWidth="mobile-lg"
              >
                *<Highlighter search={q}>{num.note}</Highlighter>
              </Prose>
            )}
            <span className="usa-sr-only">,</span>
          </li>
        ))}
      </ExpandCollapseList>
    </ul>
  )
}

Number.propTypes = {
  array: PropTypes.array,
  index: PropTypes.number,
  isPrint: PropTypes.bool,
}

const controlNumberDescriptions = [
  {
    type: 'Agency Disposition Number',
    description:
      'This identifier is used by the agency in its internal records schedule or manual.',
  },
  {
    type: 'Agency-Assigned Identifier',
    description:
      'This identifier is provided by the creating agency for various purposes.',
  },
  {
    type: 'Declassification Project Number',
    description:
      'A unique number assigned to a specific block of records (a series, several series, a specific file folder, etc.) that has been reviewed for declassification.',
  },
  {
    type: 'FOIA Tracking Number',
    description:
      'A unique number assigned to a specific block of records (a series, several series, a specific file folder, etc.) that has been reviewed for release under FOIA.',
  },
  {
    type: 'Former ARC Identifier',
    description:
      'An identifier for records which have previously been entered into the legacy ARC system.',
  },
  {
    type: 'Former Local Identifier',
    description:
      'These identifiers were created by but no longer used by local NARA units to identify particular records.',
  },
  {
    type: 'Former National Archives Identifier',
    description:
      'These represent previouslly assigned National Archives Identifiers which have been changed and are no longer used.',
  },
  {
    type: 'Former HMS/MLR Entry Number:',
    description:
      "Use for the archival material's previously-assigned identifier in the Holdings Management System (HMS) or Master Location Registry (MLR).",
  },
  {
    type: 'Government Publication Number',
    description:
      'Use when describing an item that has been formally published through the Government Printing Office and has been assigned a government publication number, also known as the Su Docs Number (Superintendent of Documents Number).',
  },
  {
    type: 'HMS/MLR Entry Number',
    description:
      "Use for the archival material's assigned identifier in the Holdings Management System (HMS) or Master Location Registry (MLR).",
  },
  {
    type: 'HMS Record Entry ID',
    description:
      'Use for archival materials with a unique system-assigned identifier for a Holdings Management System (HMS) record entry.',
  },
  {
    type: 'Inventory Entry Number',
    description:
      'Use for archival materials described in an inventory of a Record Group or Collection. A basic archival finding aid, an inventory generally includes a brief administrative history of the organization(s) as well as series descriptions of their records. Minimum descriptions generally include title, inclusive dates, quantity, arrangement, relationships to other series, and scope and content notes. Inventories may also include appendices that provide supplementary information such as container lists, folder lists, glossaries, etc. In record management, an inventory is a listing of the volume, scope, and complexity of the records, usually compiled for the purpose of creating a records schedule.',
  },
  {
    type: 'Kennedy Assassination Document ID',
    description:
      'The President John F. Kennedy Assassination Records Collection Act of 1992 required agencies that held assassination records to record information about these records on Record Identification Forms (RIFs) for input into a master database. The master database contains a unique document number for most of the records described on RIFs. For example, 104-10004-10266 is for a 9-page document from the Federal Bureau of Investigation to the Central Intelligence Agency with allegations by Joaquin Rivas Gamez.',
  },
  {
    type: 'Legislative Transfer Number',
    description:
      'Use for the unique identifier assigned by the House or Senate to archival materials transferred to the Center for Legislative Archives.',
  },
  {
    type: 'Local Identifier',
    description:
      'Use for identifiers created by local NARA units to identify particular archival materials. The local identifier may be used to capture the "series entry number" or "entry number" used in the regions to identify series. Be aware that the local identifier might not be unique. Different units may use the same local identifier for different archival materials.',
  },
  {
    type: 'NAIL Control Number',
    description:
      'Use for archival materials that have been described in the NAIL database and have been assigned a unique identifier.',
  },
  {
    type: 'NAILTEX Control Number',
    description:
      'Use for archival materials that have been described on NAILTEX forms and assigned a control number.',
  },
  {
    type: 'NUCMC Number',
    description:
      'A valid entry number for a bibliographic record as found in the National Union Catalog of Manuscript Collections (NUCMC). NUCMC is a cooperative cataloging program operated by the Library of Congress.',
  },
  {
    type: 'Other Finding Aid Identifier',
    description:
      'Use when archival materials were described in a previous finding aid, either created by NARA, by an agency, or by a donor. Describe the finding aid and finding aid number in the Variant Control Number Note.',
  },
  {
    type: 'Preliminary Checklist Identifier',
    description:
      'Use of archival materials that have been described in a preliminary checklist. A checklist is a list of documents prepared for the purposes of identification and control.',
  },
  {
    type: 'PRESNET Number',
    description:
      'Use for archival materials that have been described in the Presidential libraries Information Network (PRESNET) databases and have been assigned a unique identifier.',
  },
  {
    type: 'Ref ID',
    description:
      'Use for the unique identifier assigned to each unique file of electronic records in the Electronic Records Archives (ERA). Use only for archival materials in the legal custody of the Electronic and Special Media Records Services Division (NWME).',
  },
  {
    type: 'Search Identifier',
    description:
      'Use for archival materials that have been designated as part of a predetermined, or "canned," search strategy.',
  },
  {
    type: 'Select List Identifier',
    description:
      'Use for archival materials that have been described in a select list. A select list is a published finding aid listing items, such as textual documents, photographs, maps, etc., that bring together information from across record groups, collections, or series relating to a particular topic.',
  },
  {
    type: 'WHORM Identifier',
    description:
      'The case file number and suffix, such as "172125CU", assigned to the materials by the White House Office of Records Management.',
  },
  {
    type: 'XMIS Number',
    description:
      'Use for the unique identifier assigned to each unique file of electronic records as received or preserved in the physical or legal custody of the Electronic and Special Media Records Services Division (NWME).',
  },
  {
    type: 'Accession Number',
    description:
      "When a group of records is transferred into NARA's legal custody, this number is assigned in order to link the records with the corresponding accession documentation.",
  },
  {
    type: 'Disposition Authority Number',
    description:
      'When the disposition of a category of records have been approved by NARA, this number is assigned in order to link the record with the corresponding disposition documentation.',
  },
  {
    type: 'Records Center Transfer Number',
    description:
      "When a group of records is transferred into a record center's physical custody, this number is assigned in order to link the records with the corresponding documentation.",
  },
  {
    type: '',
    description: '',
  },
]
