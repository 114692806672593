import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import { Button } from '../Button/Button'
import { ListSeparator } from '../ListSeparator/ListSeparator'
//Hooks
import useContributionBar from './use-contribution-bar'

/**
 * The contribution bar provides buttons which control the display of the contribution panel.
 * These buttons indicate the transcription, tag, and comment status for the current digital object
 */
export const ContributionBar = ({
  contributions,
  extractedCount,
  filteredObjects,
  ...props
}) => {
  const { objectPanel, updatePanelHandler, tabs } = useContributionBar(
    extractedCount,
    filteredObjects
  )

  return (
    <div
      className={[
        'add-list-reset',
        'display-flex',
        'flex-row',
        'flex-align-center',
        'flex-justify-center',
        'grid-row',
        // 'padding-1',
        'theme-base-darkest',
        'width-full',
      ].join(' ')}
      {...props}
    >
      {/**
       * ACCESSIBILITY:
       * Elements with the role tab must either be a child of an element with the tablist role,
       * or have their id as part of the aria-owns property of a tablist.
       * This combination identifies to assistive technology that the element
       * is part of a group of related elements.
       */}
      <ListSeparator
        align="middle"
        aria-label="Contribution panel control tabs"
        color="base"
        display="inline-flex"
        distance="2px"
        nowrap
        role="tablist"
      >
        {tabs.map((tab) => (
          <div
            className="display-flex flex-align-center flex-no-wrap"
            key={tab.id}
          >
            {/**
             * ACCESSIBILITY:
             * An element with the tab role controls the visibility
             * of an associated element with the tabpanel role.
             * ex: #tag-tab controls #contribution-panel (from the contribution_panel component)
             */}
            <Button
              active={objectPanel == tab.id}
              /**
               * ACCESSIBILITY:
               * An element with the tab role should contain the aria-controls property identifying
               * a corresponding tabpanel (that has a tabpanel role) by that element's id.
               */
              aria-controls="contribution-panel"
              /**
               * ACCESSIBILITY:
               * When elements with the tab role are selected or active
               * they should have their aria-selected attribute set to true
               */
              aria-selected={objectPanel == tab.id}
              aria-label={tab.ariaLabel}
              data-testid={`nac-object-viewer--${tab.id}-panel-button`}
              iconName={tab.iconName}
              iconSize="xs"
              id={`${tab.id}-tab`}
              onClick={() => updatePanelHandler(tab.id)}
              outline={objectPanel == tab.id}
              reduced
              role="tab"
              size="3xs"
              textOnly={objectPanel !== tab.id}
              theme="base-lightest"
              thin
            >
              <ListSeparator
                delimeter="minus"
                color="base"
                size="sm"
                distance="2px"
              >
                <span
                  className={[
                    'display-none',
                    objectPanel
                      ? 'desktop-lg:display-block'
                      : 'tablet-lg:display-block',
                  ].join(' ')}
                >
                  {tab.title}
                </span>
                <span
                  className={[
                    `${tab.count == 0 ? 'text-base-light' : 'text-bold'}`,
                  ].join(' ')}
                >
                  {tab.label}
                </span>
              </ListSeparator>
            </Button>
          </div>
        ))}
      </ListSeparator>
    </div>
  )
}

ContributionBar.defaultProps = {
  contributions: [],
}

ContributionBar.propTypes = {
  extractedCount: PropTypes.number,
  contributions: PropTypes.array.isRequired,
  filteredObjects: PropTypes.array,
}
